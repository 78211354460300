import React, { useEffect } from 'react'
import sha256 from 'crypto-js/sha256'
import cryptoRandomString from 'crypto-random-string'

export default function LoginPage(props) {
  const { require_login } = props
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    let pkce_unhashed = cryptoRandomString({ length: 128, type: 'alphanumeric' })
    let pkce_hashed = sha256(pkce_unhashed)
    localStorage.setItem('pendingLogin', JSON.stringify({ pkce_hashed, pkce_unhashed }))

    let authLoginUrl = 'https://auth.autoipacket-stg.com'
    let redirectReturnUrl = `https://${window.location.host}`

    if (window.location.hostname === 'dpapp.autoipacket.com') {
      authLoginUrl = 'https://auth.autoipacket.com'
    } else if (window.location.hostname === 'localhost') {
      redirectReturnUrl = `http://${window.location.host}`
    }

    if (process.env.AUTH_LOGIN_URL) {
      authLoginUrl = process.env.AUTH_LOGIN_URL
    }

    window.location.href = `${authLoginUrl}/?redirect=${redirectReturnUrl}/auth&pkce=${pkce_hashed}&client_id=5100206${
      require_login ? '&require_login=true' : ''
    }`
  }, [])
  return <React.Fragment />
}
