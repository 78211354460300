// general: 1/4 of the reports component - child of reports - (3rd).

// TODO:
// 1. API needs implemented.
// 2. mobile support
// 3. cross browser check support
// 4. remove dummy data TODO

/* Old cell template

  <Link to={`/packetsend/view/item/${vehicleId}/${stock}`}>
            {value ? (
              <div className="absent-cell">
                <img
                  className="icon-file-absent"
                  src={FileIconAbsent}
                  alt={`${FileIconAbsent}-img`}
                />
              </div>
            ) : (
              <div className="present-cell">
                <img className="icon-file-present" src={FileIcon} alt={`${FileIcon}-img`} />
              </div>
            )}
          </Link>

*/

// @flow
import React, { Component } from 'react'
// node packages
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import { CSVLink, CSVDownload } from 'react-csv'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'

import 'react-table/react-table.css'
import { connect } from 'react-redux'
// core files
// --- actions
import ReportsActions, { getStatusReports } from '../../../../core/actions/features/reportsactions'
import ProfileActions from '../../../../core/actions/users/profileactions'
// --- dummy data
import AIPSeedsData from '../../../../core/data/AIPSeedsData'
// images
import IconDealership from '../../../../core/assets/images/icons/icon-dealership'
import IconCancel from '../../../../core/assets/images/icons/icon-x'
import PendingIcon from '../../../../core/assets/images/icons/pending-icon'
// helpers
import LoadingSpinner from '../../../helpers/loadingspinner.js'
import { ReactTableOptionsStatic } from '../../../helpers/reusables'

// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/reports.scss'

type Props = {
  DJtoken: string,
  storeId: number,
  userId: number,
  isAdmin: boolean,
  storeName: string,
}
class StatusReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableInfo: [],
      page: 1,
      loading: true,
      errored: false,
      loadingText: 'Loading...',
      //hoverColumn: -1,
      setupCalls: 0,
      pageSize: 25,
    }
  }
  componentDidMount() {
    this.getReports(this.props)
  }
  componentWillUnmount() {
    this.setState({
      tableInfo: [],
      page: 1,
      loading: true,
      errored: false,
      loadingText: 'Loading...',
      //hoverColumn: -1,
      setupCalls: 0,
    })
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.storeId !== this.state.storeId &&
      !this.state.loading &&
      !this.state.awaitingAPI
    ) {
      this.getReports(this.props)
    }
  }
  getReports(props) {
    // awaitingAPI
    this.setState({ errored: false, loading: true, awaitingAPI: true, storeId: props.storeId })

    if (!this.state.awaitingAPI) {
      getStatusReports(props.DJtoken, props.storeId, this.state.page).then(res => {
        // poorly handle an API error here!
        if (typeof res == 'string') {
          this.setState({
            loading: false,
            errored: true,
            storeId: props.storeId,
            awaitingAPI: false,
          })
        } else if (res.results.length > 0) {
          // convert cpo "y/n" to boolean
          res.results.map(function(x) {
            x.cpo == 'Y' ? (x.cpo = true) : (x.cpo = false)
            return x
          })
          // herein we build our array of columns to display using the first vehicle
          // in the results
          let columnData = []
          const firstVehicle = res.results[0]
          // we trim the 9 non-module keys in order to get our pure modules array
          // (year, make, model, stock, stock_type, vin, vehicle_id, cpo, days_in_inv)
          // *this should/could be done more safely!*
          const colsToTrim = Object.keys(firstVehicle).length - 9
          const headerData = Object.keys(firstVehicle).splice(colsToTrim * -1, colsToTrim)
          headerData.map(item => {
            columnData.push({ name: item.toString(), id: item.toString() })
          })
          this.setState({
            colHeaders: columnData,
            tableInfo: res.results,
            storeId: props.storeId,
            loading: false,
            awaitingAPI: false,
          })
        }
      })
    } else {
      console.log('ignoring excess request')
    }
  }

  // Render body cells for first 7 columns!
  ReportsColumnNormalSetup(props: Object) {
    // styles
    let backgroundColor = 'transparent'

    // states
    //const { hoverColumn } = this.state

    const value = props.original[props.column.id]

    //if (props.column.id === hoverColumn) {
    //backgroundColor = '#eee'
    //} else {
    //backgroundColor = 'transparent'
    //}
    if (props.column.Header == 'CPO') {
      return (
        <div style={{ backgroundColor, width: '100%', height: '100%' }}>
          <div className="status-report-normal-cell">
            <b style={{ color: '#4bc563' }}>{value ? 'CPO' : ''}</b>
          </div>
        </div>
      )
    } else if (props.column.Header == 'Vehicle Created') {
      return (
        <div
          //column house hover commented out for performance 6/24/19
          //onMouseEnter={() => {
          //  this.setState({ hoverColumn: props.column.id })
          //}}
          //onMouseLeave={() => {
          //  this.setState({ hoverColumn: '' })
          //}}
          style={{ backgroundColor, width: '100%', height: '100%' }}
        >
          <div className="status-report-normal-cell">{moment(value).format('ll')}</div>
        </div>
      )
    } else {
      return (
        <div
          //column house hover commented out for performance 6/24/19
          //onMouseEnter={() => {
          //  this.setState({ hoverColumn: props.column.id })
          //}}
          //onMouseLeave={() => {
          //  this.setState({ hoverColumn: '' })
          //}}
          style={{ backgroundColor, width: '100%', height: '100%' }}
        >
          <div className="status-report-normal-cell">{value}</div>
        </div>
      )
    }
  }
  ReportsHeaderSlantSetup(props: Object, title: string) {
    let backgroundColor = 'transparent'
    //const { hoverColumn } = this.state
    //if (props.column.id === hoverColumn) {
    //  backgroundColor = '$transparent-white-20'
    //} else {
    //  backgroundColor = 'transparent'
    //}

    return (
      <div
        //  onMouseEnter={() => {
        //    this.setState({ hoverColumn: props.column.id })
        //  }}
        //  onMouseLeave={() => {
        //    this.setState({ hoverColumn: '' })
        //  }}

        className="header-text-slanted"
      >
        <div className="header-text">{title}</div>
        <div className="header-back" style={{ backgroundColor }} />
      </div>
    )
  }
  ReportsColumnSlantSetup(props: Object) {
    // styles
    let backgroundColor = 'transparent'

    // states
    //const { hoverColumn } = this.state

    const value = props.original[props.column.id]
    const vehicleId = props.original.vehicle_id
    const stock = props.original.stock

    //if (props.column.id === hoverColumn) {
    //  backgroundColor = '$transparent-white-20'
    //} else {
    //  backgroundColor = 'transparent'
    //}

    return (
      <div
        //  onMouseEnter={() => {
        //    this.setState({ hoverColumn: props.column.id })
        //  }}
        //  onMouseLeave={() => {
        //    this.setState({ hoverColumn: '' })
        //  }}

        style={{ backgroundColor }}
      >
        {value ? <div className="present-cell">•</div> : <div className="absent-cell">◦</div>}
      </div>
    )
  }
  GetFormattedDateTime() {
    const now = new Date()

    const year = now.getFullYear()
    const month = now.getMonth() + 1 // Months are zero-indexed
    const day = now.getDate()

    let hours = now.getHours()
    const minutes = now.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'

    hours = hours % 12
    hours = hours ? hours : 12 // Convert hour '0' to '12'

    return `${year}_${month}_${day}_${hours}_${minutes}_${ampm}`
  }
  CalcLastPriceChangeDays(last_price_change) {
    let daysSinceLastPriceChange = null
    const currentDate = moment()

    if (last_price_change) {
      const lastPriceChangeDate = moment(last_price_change)
      daysSinceLastPriceChange = currentDate.diff(lastPriceChangeDate, 'days')
    }
    return daysSinceLastPriceChange
  }
  ReportsColumnSlantSetupLastPriceChange(props: Object) {
    // styles
    let backgroundColor = 'transparent'
    const last_price_change = props.original[props.column.id]

    let daysSinceLastPriceChange = this.CalcLastPriceChangeDays(last_price_change)

    return (
      <div style={{ backgroundColor }}>
        {daysSinceLastPriceChange ? daysSinceLastPriceChange : 'N/A'}
      </div>
    )
  }
  ReportsColumnMSRPSetup(props) {
    const { value } = props
    let backgroundColor = 'transparent'
    let cell = null
    if (props.original.days_in_inventory < 3) {
      cell = (
        <div title={'MSRPs may take up to 2 days to load'} className="pending-cell">
          <PendingIcon />
        </div>
      )
    } else if (value) {
      cell = <div className="present-cell">•</div>
    } else {
      cell = <div className="absent-cell">◦</div>
    }
    cell = <div style={{ backgroundColor }}>{cell}</div>
    return cell
  }

  ReportsColumnsContainer() {
    const tableInfo = this.state.tableInfo
    const columnsArray = [
      {
        name: 'Stock #',
        Header: 'Stock #',
        id: 'stock',
        accessor: d => d.stock,
        Cell: props => {
          return (
            <span>
              <Link to={`/packet/view/${props.original.vehicle_id}`}>{props.original.stock}</Link>
            </span>
          )
        },
        width: 120,
        headerClassName: 'stick-left-header',
        className: 'stick-left',
        filterable: true,
      },
      {
        name: 'VIN',
        Header: 'VIN',
        id: 'vin',
        accessor: d => d.vin,
        width: 180,
        filterable: true,
      },
    ]
    const rowHeaders = [
      {
        name: 'CPO',
        id: 'cpo',
      },
      {
        name: 'Year',
        id: 'year',
      },
      {
        name: 'Make',
        id: 'make',
      },
      {
        name: 'Model',
        id: 'model',
      },
      {
        name: 'Vehicle Created',
        id: 'aip_vehicle_created_date',
      },
      {
        name: 'Days in Inv.',
        id: 'days_in_inventory',
      },
    ]
    const slantHeaders = [
      {
        name: 'Images',
        id: 'images',
      },
      {
        name: 'Invoice',
        id: 'invoice',
      },
      {
        name: 'VMI',
        id: 'vmi',
      },
      {
        name: 'MSRP',
        id: 'msrp',
      },
      {
        name: 'Sticker',
        id: 'sticker',
      },
      {
        name: 'Rate',
        id: 'rate',
      },
      {
        name: 'Warranty',
        id: 'warranty',
      },
      {
        name: 'Brochure',
        id: 'brochure',
      },
      {
        name: 'PDF',
        id: 'pdf',
      },
      {
        name: 'Delivery Receipt',
        id: 'delivery_receipt',
      },
      {
        name: 'Title',
        id: 'title',
      },
      {
        name: 'MPG',
        id: 'mpg',
      },
      {
        name: 'Why Buy',
        id: 'why_buy_from_us',
      },
      {
        name: 'AIP Brochure',
        id: 'stock',
      },
      {
        name: 'One Low Price',
        id: 'one_low_price',
      },
      {
        name: 'About Us',
        id: 'about_us',
      },
    ]
    const slantHeadersTrue = this.state.colHeaders
    rowHeaders.map(header =>
      columnsArray.push({
        name: header.name,
        Header: header.name,
        Cell: props => {
          return this.ReportsColumnNormalSetup(props)
        },
        width:
          header.id == 'cpo' || header.id == 'days_in_inventory' || header.id == 'year' ? 60 : 125,
        id: header.id,
        accessor: d => d[header.id],
        filterable:
          header.id == 'days_in_inventory' ||
          header.id == 'cpo' ||
          header.id === 'aip_vehicle_created_date'
            ? false
            : true,
      }),
    )
    if (slantHeadersTrue == undefined) {
      slantHeaders.map(header =>
        columnsArray.push({
          name: header.name,
          Header: props => {
            return this.ReportsHeaderSlantSetup(props, header.name)
          },
          Cell: props => {
            return this.ReportsColumnSlantSetup(props)
          },
          id: header.id,
          accessor: d => d[header.id],
          width: 50,
          headerClassName: 'rotate-45',
          filterable: false,
        }),
      )
    } else {
      slantHeadersTrue.map(header => {
        if (header.id === 'MSRP') {
          columnsArray.push({
            name: header.name,
            Header: props => {
              return this.ReportsHeaderSlantSetup(props, header.name)
            },
            Cell: props => {
              return this.ReportsColumnMSRPSetup(props)
            },
            id: header.id,
            accessor: d => d[header.id],
            width: 50,
            headerClassName: 'rotate-45',
            filterable: false,
          })
        } else if (header.id === 'last_price_change') {
          columnsArray.push({
            name: 'Days Since Last Price Change',
            Header: props => {
              return this.ReportsHeaderSlantSetup(props, 'Days Since Last Price Change')
            },
            Cell: props => {
              return this.ReportsColumnSlantSetupLastPriceChange(props)
            },
            id: header.id,
            accessor: d => d[header.id],
            width: 50,
            headerClassName: 'rotate-45',
            filterable: false,
          })
        } else if (header.id !== 'aip_vehicle_created_date') {
          columnsArray.push({
            name: header.name,
            Header: props => {
              return this.ReportsHeaderSlantSetup(props, header.name)
            },
            Cell: props => {
              return this.ReportsColumnSlantSetup(props)
            },
            id: header.id,
            accessor: d => d[header.id],
            width: 50,
            headerClassName: 'rotate-45',
            filterable: false,
          })
        }
      })
    }
    return columnsArray
  }

  filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true
  }

  renderCSVExport() {
    const { tableInfo } = this.state
    const { storeName, store } = this.props
    const headers = this.ReportsColumnsContainer().map(column => {
      return { label: column.name, key: column.id }
    })
    let updatedTableInfo = tableInfo.map(vehicle => {
      let updatedVehicle = cloneDeep(vehicle)
      Object.keys(updatedVehicle).map(field => {
        if (!updatedVehicle[field]) {
          updatedVehicle[field] = '-'
        } else if (field === 'last_price_change') {
          let daysSinceLastPriceChange = this.CalcLastPriceChangeDays(updatedVehicle[field])
          updatedVehicle[field] =
            daysSinceLastPriceChange === null ? 'N/A' : daysSinceLastPriceChange
        }
      })
      return updatedVehicle
    })
    return (
      <CSVLink
        id="csv_export"
        data={updatedTableInfo}
        headers={headers}
        filename={`${storeName.replace(' ', '_')}_${
          store.store_type === 1 ? 'New' : 'Pre_Owned'
        }_Status_Report_${this.GetFormattedDateTime()}.csv`}
      >
        Export Report to CSV
      </CSVLink>
    )
  }
  render() {
    const { loading, errored, page, tableInfo, pageSize } = this.state
    const count = tableInfo.length
    if (errored) {
      return (
        <div className="status-report-error">
          <h2>
            An Error has occured retrieving this report.
            <br />
            If this error persists, please contact our support department.
          </h2>
        </div>
      )
    }
    if (loading) {
      return (
        <LoadingSpinner
          loading
          timeout={90000}
          message={() => {
            return (
              <span onClick={() => location.reload()}>
                Thanks For Being Patient, Sometimes This Page Takes A Long Time
                <br />
                Click to Refresh
              </span>
            )
          }}
        />
      )
    }
    return (
      <React.Fragment>
        <div id="scroll-mssg">This table scrolls horizontally</div>
        Hold <b>Shift</b> to Sort By Multiple Columns
        {this.renderCSVExport()}
        {count > 0 && (
          <span
            onClick={() => {
              this.updatePage(2)
            }}
          >
            {' '}
            {`Displaying ${count} ${count > 1 ? 'Total ' : ''}Result${count > 1 ? 's' : ''}`}
          </span>
        )}
        <ReactTable
          showPagination={true}
          showPaginationTop={true}
          showPaginationBottom={true}
          showPageSizeOptions={true}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          showPageJump={false}
          defaultPageSize={pageSize}
          defaultSortDesc={true}
          multiSort={true}
          resizable={false}
          filterable={true}
          manual={false}
          previousText="Previous"
          nextText="Next"
          noDataText="No results found."
          pageText={null}
          ofText="of"
          rowsText="rows"
          minRows={5}
          collapseOnSortingChange={false}
          collapseOnPageChange={false}
          collapseOnDataChange={false}
          freezeWhenExpanded={false}
          className="-striped -highlight report_table packet_content_table table-header-rotated"
          defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row)}
          sortable={true}
          loading={loading}
          data={tableInfo}
          resolveData={data => data.map(row => row)}
          columns={this.ReportsColumnsContainer()}
          //page={page - 1}
        />
        <div className="packet-status-legend">
          <div align="center">
            <div className="packet-legend-icon">•</div>
            <div className="packet-legend-text">Content is present</div>
          </div>
          <div align="center">
            <div className="packet-legend-icon absent-cell">◦</div>
            <div className="packet-legend-text">Content is absent</div>
          </div>
          <div align="center">
            <div className="packet-legend-icon">
              <PendingIcon />
            </div>
            <div className="packet-legend-text">Content is pending</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storeId:
    state.tools.activeStoreId === -1
      ? state.authentication.user.user.profile.default_company_store
      : state.tools.activeStoreId,
  storeName: state.tools.activeStoreName,
  store: state.tools.activeStore,
})
export default connect(mapStateToProps, null)(StatusReports)
