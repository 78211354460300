import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import LoadingSpinner from '../../helpers/loadingspinner'
import ToolTip from '../../helpers/tooltip'

import {
  ADD_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
} from '../../../core/_constants/emailTemplateTypes'
import { VIEW_EMAIL_TEMPLATE } from '../../../core/_constants/modalTypes'
import { showModal } from '../../../core/_actions/modalActions'
import {
  getUserTemplates,
  updateUserTemplateList,
  updateEmailTemplate,
  editTemplate,
} from '../../../core/actions/emailTemplateActions'

import IconAddItem from '../../../core/assets/images/icons/icon-additem'
import EditIcon from '../../../core/assets/images/icons/editicon'
import IconX from '../../../core/assets/images/icons/icon-x'
import Warning from '../../../core/assets/images/icons/warning'
import EyeOpenIcon from '../../../core/assets/images/icons/eye-open_icon'

import '../styles/userEmailTemplates.scss'

function UserEmailTemplates(props) {
  const {
    userTemplates,
    token,
    updateUserTemplateListConnect,
    showModalConnect,
    editTemplateConnect,
    user_id,
    companyName,
    defaultStoreName,
  } = props
  const [localUserTemplateList, setLocalUserTemplateList] = useState(userTemplates)
  const [searchValue, setSearchValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDefault, setIsDefault] = useState(false)
  const templateType = 'User'
  let setTimeoutId

  const fetchUserTemplates = () => {
    setIsLoading(true)
    getUserTemplates(token)
      .then(res => {
        console.log('User Templates')
        console.log(res.results)
        setIsLoading(false)
        if (res) {
          updateUserTemplateListConnect(res.results)
          setLocalUserTemplateList(userTemplates)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const handleInputChange = value => {
    clearTimeout(setTimeoutId)
    setSearchValue(value)
    if (value) {
      setIsSearching(true)
      setTimeoutId = setTimeout(() => {
        const filteredTemplateList = userTemplates.filter(
          template =>
            template.name.toLowerCase().includes(value.toLowerCase()) ||
            template.message.toLowerCase().includes(value.toLowerCase()),
        )
        setLocalUserTemplateList(filteredTemplateList)
      }, 400)
    } else {
      setIsSearching(false)
    }
  }

  const handleInputBlur = () => {
    if (searchValue.length > 0) {
    } else {
      setIsSearching(false)
    }
  }

  const handleClearSearchField = () => {
    setSearchValue('')
    setIsSearching(false)
  }

  const handleDefaultTemplate = () => {
    setIsDefault(!isDefault)
  }

  const handleTemplateActivity = emailTemplate => {
    if (emailTemplate.active) {
      const editedTemplate = emailTemplate
      editedTemplate.active = false
      updateEmailTemplate(token, editedTemplate, editedTemplate.id)
        .then(res => {
          if (res) {
            console.log(res)
            editTemplateConnect(emailTemplate, editedTemplate, templateType)
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      const editedTemplate = emailTemplate
      editedTemplate.active = true
      updateEmailTemplate(token, editedTemplate, editedTemplate.id)
        .then(res => {
          if (res) {
            console.log(res)
            editTemplateConnect(emailTemplate, editedTemplate, templateType)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  useEffect(() => {
    userTemplates.length > 0 ? null : fetchUserTemplates()
    clearTimeout(setTimeoutId)
  }, [])

  const renderSearchBarDisclaimer = () => {
    return (
      <div className="search-disclaimer">
        In the search bars below you can search for a <b>specific </b> template. The search looks
        through the list of templates and matches the keywords entered with the <b>name</b> as well
        as the <b>message</b> . So you can filter templates by searching through their <b>names</b>{' '}
        or the actual <b>message</b>.
      </div>
    )
  }

  const renderSearchBar = () => {
    return (
      <div className="searchTemplates">
        <input
          type="text"
          name="search"
          placeholder="You can search for a specific template here"
          maxLength="99"
          className="searchTemplates"
          value={searchValue}
          onChange={e => handleInputChange(e.target.value)}
          onBlur={() => handleInputBlur(false)}
        />
        <button
          className={isSearching ? 'inputClose' : 'inputClose-hidden'}
          onClick={() => handleClearSearchField()}
          type="button"
        >
          <IconX />
        </button>
      </div>
    )
  }

  const renderNoSearchResultsMessage = () => {
    return (
      <div className="noSearchResults">
        <Warning />
        There are no User template names or messages that contain {searchValue}.
      </div>
    )
  }

  const renderPrivacySlide = emailTemplate => {
    return (
      <div className="privacySlide" style={{ animation: ` 1500ms fadeIn`, alignItems: 'center' }}>
        <span
          className={`packetSendItalicStyle ${emailTemplate.active ? 'transparent' : ''}`}
          style={{ fontSize: '1.0em' }}
        ></span>
        <label className="switch">
          <input
            onChange={() => handleTemplateActivity(emailTemplate)}
            type="checkbox"
            checked={emailTemplate.active}
          />
          <span className="slider round" />
        </label>
      </div>
    )
  }

  const renderTableHeader = () => {
    return (
      <div className="templateHeader">
        <span className="templateName">Name</span>
        <ToolTip
          onHover
          tip={
            <React.Fragment>
              This Toggle allows you to activate or deactivate this template.
              <br />
              Only active templates can be rendered in the packet send modals.
            </React.Fragment>
          }
        >
          <span className="templateActivityToggle">Enable/disable template</span>
        </ToolTip>
        <span className="editTemplate">Edit Template</span>
        <span className="viewTemplate">View Template</span>
        <span className="setDefaultTemplate"></span>
      </div>
    )
  }

  const renderTemplateRow = (emailTemplate, index) => {
    return (
      <div className="templateRow" key={index.toString()}>
        <span className="templateName" title={emailTemplate.name}>
          {emailTemplate.name}
        </span>
        {renderPrivacySlide(emailTemplate)}
        <span
          className="editTemplate"
          onClick={() =>
            showModalConnect(EDIT_EMAIL_TEMPLATE, {
              selectedTemplate: emailTemplate,
              templateType: templateType,
              storeName: defaultStoreName,
            })
          }
        >
          <EditIcon />
        </span>
        <span
          className="viewTemplate"
          onClick={() =>
            showModalConnect(VIEW_EMAIL_TEMPLATE, {
              selectedTemplate: emailTemplate,
              templateType: templateType,
              storeName: defaultStoreName,
            })
          }
        >
          <EyeOpenIcon />
        </span>
        <span className="setDefaultTemplate">
          <button
            className={isDefault ? 'isDefault' : 'isNotDefault'}
            onClick={() => handleDefaultTemplate(emailTemplate)}
          >
            {isDefault ? 'Default Template' : 'Set As default'}
          </button>
        </span>
      </div>
    )
  }

  const renderNoTemplatesMessage = templateType => {
    return (
      <div className="noTemplatesError">
        <Warning />
        There are no {templateType} email templates at this time. You can use the Add Template
        button to create one.
      </div>
    )
  }

  return (
    <div className="user-emailTemplates">
      <span className="disclaimer">
        On this page you can create, edit and delete additional email templates used on packet
        shares.
      </span>

      <div className="addTemplate">
        <button
          type="button"
          onClick={() =>
            showModalConnect(ADD_EMAIL_TEMPLATE, {
              templateType: templateType,
              userId: user_id,
              storeIds: [],
            })
          }
        >
          <span>
            <IconAddItem className="addIcon" />
            Add Template
          </span>
        </button>
      </div>

      {userTemplates.length > 0 && renderSearchBarDisclaimer()}

      <div className="userTemplates">
        {isLoading && <LoadingSpinner loading wheel />}
        {userTemplates.length === 0 && !isLoading && renderNoTemplatesMessage('user')}
        {userTemplates.length > 0 && renderSearchBar()}
        {userTemplates.length > 0 && renderTableHeader()}
        {userTemplates.length > 0 &&
          !isSearching &&
          userTemplates.map(template =>
            renderTemplateRow(template, userTemplates.indexOf(template)),
          )}
        {localUserTemplateList.length === 0 && isSearching && renderNoSearchResultsMessage()}
        {localUserTemplateList.length > 0 &&
          isSearching &&
          localUserTemplateList.map(template =>
            renderTemplateRow(template, localUserTemplateList.indexOf(template)),
          )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  userTemplates: state.emailTemplates.userTemplates,
  token: state.authentication.user.token,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  companyName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_name,
  defaultStoreName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.store_full_name,
})

const mapDispatchToProps = {
  updateUserTemplateListConnect: updateUserTemplateList,
  showModalConnect: showModal,
  editTemplateConnect: editTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEmailTemplates)
