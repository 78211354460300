import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { debounce } from 'debounce'
// core files
import HeaderLinkSeeds from '../../core/data/HeaderLinkSeeds'
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// redux
import { showModal, hideModal } from '../../core/_actions/modalActions'
import { REPORT_ISSUE_MODAL } from '../../core/_constants/modalTypes'
import { stashUrlHistory } from '../../core/_actions/url_infoActions'
// images
import Logo from './logo'
import AccountSettingsIcon from '../../core/assets/images/icons/accountsettings-icon'
import ReportIssueIcon from '../../core/assets/images/icons/reportissue-icon'
import ArrowUpIcon from '../../core/assets/images/icons/arrow-up_icon'
import ArrowDownIcon from '../../core/assets/images/icons/arrow-down_icon'
import ProfileIcon from '../../core/assets/images/icons/profileicon.png'
import TrainingIcon from '../../core/assets/images/icons/icon-training'
import HelpIcon from '../../core/assets/images/icons/help-icon'
import HelpIconX from '../../core/assets/images/icons/help-icon-x'
import IconEmail from '../../core/assets/images/icons/icon-email'
import IconPhone from '../../core/assets/images/icons/icon-phone'
import IconLogout from '../../core/assets/images/icons/icon-logout'
import CogIcon from '../../core/assets/images/icons/cog-icon'
import VideoIcon from '../../core/assets/images/icons/video-icon'
import AIPIconJPG from '../../core/assets/images/icons/AutoiPacketWhtRingMobile.png'
import IconExternalLink from '../../core/assets/images/icons/icon-external-link'
// css
import '../styles/universalstyles.scss'
import './styles/layout.scss'

type Props = {
  userInfo: Object,
  showModal: boolean,
  hideModal: Boolean,
}

class Header extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      userInfo: this.props.userInfo,
      userAvatar: this.props.userInfo.avatar,
      helpShown: false,
      userOpen: false,
      showUserSubnavMobile: false,
      isDesktop: null,
    }

    // bindings
    this.wrapperRef = React.createRef()
    this.getScreenSize = this.getScreenSize.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.logoLinkLogic = this.logoLinkLogic.bind(this)
    this.handleClickOutsideHelpTip = this.handleClickOutsideHelpTip.bind(this)
    this.PrimaryHeaderLinksContainer = this.PrimaryHeaderLinksContainer.bind(this)
    this.DesktopHeaderUserSection = this.DesktopHeaderUserSection.bind(this)
    this.DesktopHeaderSettingsToggle = this.DesktopHeaderSettingsToggle.bind(this)
    this.AdminSettingsLink = this.AdminSettingsLink.bind(this)
    this.AccountSettingsLink = this.AccountSettingsLink.bind(this)
    this.ReportIssueModal = this.ReportIssueModal.bind(this)
    this.UserTrainingLink = this.UserTrainingLink.bind(this)
    this.HelpCenterLink = this.HelpCenterLink.bind(this)
    this.LogoutLink = this.LogoutLink.bind(this)
    this.StaffToolsLink = this.StaffToolsLink.bind(this)
    this.ZoomLink = this.ZoomLink.bind(this)
    this.DesktopHelpIcon = this.DesktopHelpIcon.bind(this)
    this.DesktopHelptip = this.DesktopHelptip.bind(this)
    this.DesktopHeaderUserDropdown = this.DesktopHeaderUserDropdown.bind(this)
    this.MobileUserSection = this.MobileUserSection.bind(this)
    this.MobileHeaderClosedContainer = this.MobileHeaderClosedContainer.bind(this)
    this.MobileHeaderOpenContainer = this.MobileHeaderOpenContainer.bind(this)
  }

  state: {
    userInfo: Object,
    userAvatar: string,
    helpShown: boolean,
    userOpen: boolean,
    showUserSubnavMobile: boolean,
    isDesktop: boolean,
  }

  componentDidMount() {
    this.props.stashUrlHistory(window.location)

    this.getScreenSize()
    window.addEventListener('resize', debounce(this.getScreenSize, 500))

    document.addEventListener('mousedown', this.handleClick, false)
    document.addEventListener('mouseup', this.handleClickOutsideHelpTip)
  }

  componentWillReceiveProps(newProps: Props) {
    this.setState({ userInfo: newProps.userInfo })
  }

  componentDidUpdate() {
    this.props.stashUrlHistory(window.location)
  }

  componentWillUnmount() {
    this.setState({
      userInfo: {},
      userAvatar: '',
      helpShown: false,
      userOpen: false,
      showUserSubnavMobile: false,
    })

    document.removeEventListener('mouseup', this.handleClickOutsideHelpTip)
    window.removeEventListener('resize')
  }

  // ***********************LETS*SETUP*FUNCTIONS***********************
  getScreenSize = () => {
    if (window.innerWidth >= 769) {
      this.setState({ isDesktop: true })
    } else if (window.innerWidth <= 768) {
      this.setState({ isDesktop: false })
    }
  }

  handleClick = event => {
    const { userOpen } = this.state
    if (this.node != null && !this.node.contains(event.target) && userOpen) {
      this.setState({ userOpen: false })
    }
  }

  handleClickOutsideHelpTip(event) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ helpShown: !this.state.helpShown })
    }
  }

  logoLinkLogic() {
    if (window.location.href.indexOf('/packets') > -1) {
      return <Logo styleClassName="packetLogo" />
    }
    return (
      <NavLink to="/packets">
        <Logo styleClassName="packetLogo" />
      </NavLink>
    )
  }

  // ***********************LETS*SETUP*DESKTOP*CONTAINERS***********************

  //   Admin Cog Icon beside iPacket Header Logo
  DesktopHeaderSettingsToggle() {
    const { userInfo } = this.state
    if (userInfo.is_admin) {
      return (
        <NavLink to="/adminsettings/basics" className="cogContainer">
          <CogIcon />
        </NavLink>
      )
    }
    return null
  }

  PrimaryHeaderLinksContainer() {
    const { userInfo, isDesktop } = this.state

    let linkEntries = HeaderLinkSeeds.header_link_entries
    if (userInfo.is_admin) {
      linkEntries = HeaderLinkSeeds.header_link_entries_admin
    }

    const linkArray = []
    let count = 0
    if (linkEntries && linkEntries.length > 0) {
      for (const entry of linkEntries) {
        const urlName = window.location.href.match(entry.page_url.split('/')[1])
        const urlNameBoolean = urlName !== null && urlName !== undefined

        const stickerPullStyle = `headerTextStyle ${
          isDesktop && entry.page_title_name == 'Sticker Pull' ? 'headerSmallText' : ''
        }`
        const isUrlNull = `${urlNameBoolean ? 'active' : ''}`

        const currentLinkStyle = `${
          isDesktop ? 'headerLinkStyle' : 'primaryNavLinkMobile headerMobileItemContainerStyle'
        } ${stickerPullStyle} ${isUrlNull}`

        const linkToPush = (
          <NavLink
            onClick={() => {
              this.setState({ userOpen: false })
            }}
            key={count++}
            to={entry.page_url}
            className={currentLinkStyle}
          >
            {entry.page_icon}
            {entry.page_title_name}
          </NavLink>
        )
        if (entry.page_deferToPropBool) {
          if (this.props[entry.page_deferToPropBool]) {
            linkArray.push(linkToPush)
          }
        } else if (entry.page_show) {
          linkArray.push(linkToPush)
        } else if (!entry.page_show && userInfo.is_admin) {
          linkArray.push(linkToPush)
        }
      }
    }

    return (
      <div className={isDesktop ? 'flexRow' : 'flexColumn'} style={{ justifyContent: 'center' }}>
        {linkArray}
      </div>
    )
  }

  ZoomLink() {
    return (
      <a href="https://zoom.us/start/videomeeting" className="header-zoom" target="_blank">
        <button
          className="headerLinkStyle"
          style={{
            verticalAlign: 'top',
            fontWeight: 'bold',
          }}
          key="zoom-training"
          target="_blank"
          type="button"
        >
          <span className="dropDownTextStyle zoom-text-cont">
            <div className="zoom-text">
              <span>Start </span>
              <span>Zoom Meeting</span>
            </div>
            <span>
              <VideoIcon color="white" />
            </span>
          </span>
        </button>
      </a>
    )
  }

  DesktopHelpIcon() {
    return (
      <div id="help-icon" onClick={() => this.setState({ helpShown: !this.state.helpShown })}>
        {!this.state.helpShown ? <HelpIcon /> : <HelpIconX />}
      </div>
    )
  }

  DesktopHelptip() {
    return (
      <div id="help-tip" ref={this.wrapperRef}>
        <h1>Contact Support</h1>
        <div align="right">
          <span>
            <IconPhone />
            <a href="tel:1-855-893-9340">855-893-9340</a>
          </span>
          <br />
          <span id="help-email">
            <IconEmail />
            <a href="mailto:support@autoipacket.com">SUPPORT@AUTOIPACKET.COM</a>
          </span>
        </div>
      </div>
    )
  }

  //   User Profile Btn
  DesktopHeaderUserSection() {
    const { userInfo, userOpen, userAvatar } = this.state
    const firstName = userInfo.first_name

    return (
      <button
        className="headerUserButtonStyle"
        onClick={() => {
          this.setState({ userOpen: !userOpen })
        }}
      >
        <div>
          <div className="headerUserImgContainerStyle">
            <img
              src={userAvatar !== '' ? userAvatar : ProfileIcon}
              alt={`${userInfo.username}-Profile-Img`}
              className="headerUserImgStyle"
            />
          </div>

          <div className="headerNameBox">
            <div className="headerUserNameStyle">{firstName}</div>
          </div>
        </div>
        {userOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
    )
  }

  AdminSettingsLink() {
    return (
      <Link
        to="/adminsettings/basics"
        onClick={() => {
          this.setState({ userOpen: false })
        }}
        key="admin-settings"
        className={
          this.state.isDesktop ? 'headerUserDropDownStyle' : 'headerMobileItemContainerStyle'
        }
      >
        <span className="dropDownTextStyle">
          <CogIcon /> Admin Settings
        </span>
      </Link>
    )
  }

  AccountSettingsLink(entry) {
    return (
      <Link
        to="/accountsettings"
        onClick={() => {
          this.setState({ userOpen: false })
        }}
        className={`accountSettings ${
          this.state.isDesktop ? 'headerUserDropDownStyle' : 'headerMobileItemContainerStyle'
        }`}
      >
        <span className="dropDownTextStyle">
          <AccountSettingsIcon /> {entry.page_title_name}
        </span>
      </Link>
    )
  }

  ReportIssueModal() {
    return (
      <button
        className="headerUserDropDownStyle"
        key="report-issue"
        onClick={() => {
          this.props.showModal(REPORT_ISSUE_MODAL, {})
          this.setState({ userOpen: false })
        }}
      >
        <span className="dropDownTextStyle reportIssueIcon">
          <ReportIssueIcon />
          Report Issue
        </span>
      </button>
    )
  }

  UserTrainingLink() {
    return (
      <a
        href="https://app.livestorm.co/autoipacket"
        key="user-training"
        className={
          this.state.isDesktop ? 'headerUserDropDownStyle' : 'headerMobileItemContainerStyle'
        }
        target="_blank"
        onClick={() => {
          this.setState({ userOpen: false })
        }}
      >
        <span className="dropDownTextStyle">
          <TrainingIcon />
          User Training <IconExternalLink />
        </span>
      </a>
    )
  }

  HelpCenterLink() {
    return (
      <a
        href="https://intercom.help/ipacket/en/"
        key="help-center"
        className={`help-center
          ${this.state.isDesktop ? 'headerUserDropDownStyle' : 'headerMobileItemContainerStyle'}`}
        target="_blank"
        onClick={() => {
          this.setState({ userOpen: false })
        }}
      >
        <span className="dropDownTextStyle">
          <HelpIcon />
          Help Center <IconExternalLink />
        </span>
      </a>
    )
  }

  LogoutLink() {
    return (
      <Link
        to="/logout"
        className={`logout ${
          this.state.isDesktop ? 'headerUserDropDownStyle' : 'headerMobileItemContainerStyle'
        }`}
      >
        <span className="dropDownTextStyle">
          <IconLogout />
          Logout
        </span>
      </Link>
    )
  }

  StaffToolsLink() {
    return (
      <Link
        to="/staff-tools/search"
        onClick={() => {
          this.setState({ userOpen: false })
        }}
        key="staff-tools"
        className={
          this.state.isDesktop ? 'headerUserDropDownStyle' : 'headerMobileItemContainerStyle'
        }
      >
        <span className="dropDownTextStyle">
          <CogIcon />
          Staff Tools
        </span>
      </Link>
    )
  }

  //   Set up the Desktop Header Dropdown
  DesktopHeaderUserDropdown() {
    const { userInfo } = this.state
    const dropdownEntries = HeaderLinkSeeds.header_link_hover_entries

    const dropdownArray = []

    // adding admin settings link
    if (userInfo.is_admin) {
      dropdownArray.push(this.AdminSettingsLink())
    }

    if (dropdownEntries && dropdownEntries.length > 0) {
      for (const entry of dropdownEntries) {
        const checkOnClickAccount = entry.page_action_press === 'account-settings'

        if (checkOnClickAccount) {
          dropdownArray.push(this.AccountSettingsLink(entry))
        }
      }
      dropdownArray.push(this.LogoutLink())
    }

    dropdownArray.splice(dropdownArray.length - 1, 0, this.ReportIssueModal())

    dropdownArray.splice(3, 0, this.HelpCenterLink())

    dropdownArray.splice(3, 0, this.UserTrainingLink())

    if (this.props.showStaffTools) {
      dropdownArray.push(this.StaffToolsLink())
    }

    return <div className="headerDropdownSectionStyle">{dropdownArray}</div>
  }

  // User Profile Mobile SubNav Item
  MobileUserSection() {
    const acctSettingsLinkEntry = HeaderLinkSeeds.header_link_hover_entries[0]
    const { userInfo, showUserSubnavMobile, userAvatar } = this.state
    const firstName = userInfo.first_name
    const lastName = userInfo.last_name

    return (
      <div>
        <button
          className="headerMobileItemContainerStyle"
          onClick={() => {
            this.setState({ showUserSubnavMobile: !showUserSubnavMobile })
          }}
        >
          <div className="headerMobileUserImgContStyle">
            <img
              src={userAvatar !== '' ? userAvatar : ProfileIcon}
              alt={`${userInfo.username}-Profile`}
              className="headerMobileUserImgStyle"
            />
          </div>
          {firstName} {lastName}
          {showUserSubnavMobile ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </button>
        {showUserSubnavMobile ? (
          <div className="headerMobileSubnavContainerStyle">
            {this.AccountSettingsLink(acctSettingsLinkEntry)}
            {this.LogoutLink()}
          </div>
        ) : null}
      </div>
    )
  }

  MobileHeaderClosedContainer() {
    const { userOpen } = this.state

    return (
      <button
        onClick={() => {
          this.setState({ userOpen: !userOpen, showUserSubnavMobile: false })
        }}
        className={`headerMobileTopContainerStyle AIPremoveButtonStyle ${userOpen ? ' open' : ''}`}
      >
        <div className="packetIconContainerStyle">
          <img src={AIPIconJPG} className="packetIconStyle" alt="packet-icon" />
        </div>
        {userOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
    )
  }

  MobileHeaderOpenContainer() {
    return (
      <div className="headerMobileOpenContainerStyle">
        <div className="headerMobileOpenInnerContainerStyle">
          <div style={{ height: '60px' }} />
          {this.PrimaryHeaderLinksContainer()}
          {this.HelpCenterLink()}
          {this.UserTrainingLink()}
          {this.StaffToolsLink()}
          {this.AdminSettingsLink()}
          {this.MobileUserSection()}
        </div>
      </div>
    )
  }

  // Render it All Responsively and Responsibly ;-)
  props: Props

  render() {
    // mobile header
    if (!this.state.isDesktop) {
      let styleObject = { padding: '0' }

      const { userOpen } = this.state

      let openContainer = null

      if (userOpen) {
        styleObject = {
          position: 'fixed',
          padding: '0',
          zIndex: '99999',
          top: '0px',
        }
        openContainer = <div>{this.MobileHeaderOpenContainer()}</div>
      }

      return (
        <div className="flexColumn headerMainStyle" style={styleObject}>
          {this.MobileHeaderClosedContainer()}
          {openContainer}
        </div>
      )
    }
    //   desktop header
    return (
      <div ref={node => (this.node = node)} className="headerMainStyle">
        <div
          style={{
            float: 'left',
            width: '30%',
            verticalAlign: 'top',
            minWidth: '200px',
            height: '70px',
            lineHeight: '70px',
          }}
        >
          {this.logoLinkLogic()}
          {this.DesktopHeaderSettingsToggle()}
        </div>
        <div
          align="center"
          style={{
            display: 'inline-block',
            width: '40%',
            verticalAlign: 'top',
            margin: '0 auto',
          }}
        >
          {this.PrimaryHeaderLinksContainer()}
        </div>
        <div
          align="right"
          style={{
            float: 'right',
            display: 'inline-block',
            width: '30%',
            verticalAlign: 'middle',
            overflow: 'hidden',
          }}
        >
          {this.ZoomLink()}
          {this.DesktopHelpIcon()}
          {this.DesktopHeaderUserSection()}
        </div>
        {this.state.helpShown ? this.DesktopHelptip() : null}
        {this.state.userOpen ? this.DesktopHeaderUserDropdown() : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  showStaffTools:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.is_staff,
  showStickerPull:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.sticker_puller_enabled,
  showAppraisal:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.appraisal_tool_enabled,
  currentLocation: window.location.href,
})

const mapDispatchToProps = {
  stashUrlHistory,
  hideModal,
  showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoWrapper(Header))
