import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import Collapsible from 'react-collapsible'
import moment from 'moment-timezone'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group'
import Paginator from '../../../helpers/paginator'
import {
  generateMonthlyActivityReports,
  getAvailableReportTypes,
  generateCustomActivityReport,
  getCustomReportRequestHistory,
} from '../../../../core/actions/features/reportsactions'
import { AIP_DJAPI_URLS } from '../../../../core/config/aipDjapi'
import LoadingSpinner from '../../../helpers/loadingspinner'
import NewViewIcon from '../../../../core/assets/images/icons/icon-external-link'

// TODO:
// 1. hopefully api will soon include ability to pass a storeId to the user report
// request history EP so we can filter results for each store when user uses store selector
// dropdown in activity reports page.
// 2. also to have the weblink and start date and end date selections return in separate fields,
// from when we submitted object to generate a custom report

function ActivityReportTableItem(props) {
  const { label, web_link, date_range, report_type, exp_date, last_requested, date_created } = props

  const dateRange = date_range ? date_range : null
  const expDate = exp_date ? moment(exp_date).format('MMM DD, YYYY') : null
  const lastRequested = last_requested ? moment(last_requested).format('MMM DD, YYYY') : null
  const dateCreated = date_created ? moment(date_created).format('MMM DD, YYYY') : null
  let reportType
  if (report_type == 1) {
    reportType = 'Store Activity Report'
  } else if (report_type == 2) {
    reportType = 'Marketplace Activity Report'
  } else {
    reportType = null
  }

  if (label && web_link) {
    return (
      <a href={web_link} target="_blank">
        <div className="activity-report-table-item">
          <div className="activity-report-table-item-title">{label}</div>
          <div className="activity-report-table-item-icon-toggle">
            <NewViewIcon />
          </div>
        </div>
        <div className="custom-report-details">
          <div>{dateRange ? `Dates: ${dateRange}` : null}</div>
          <div>{reportType ? `Type: ${reportType}` : null}</div>
          <div>{expDate ? `Expires: ${expDate}` : null}</div>
          <div>{lastRequested ? `Last Requested: ${lastRequested}` : null}</div>
          <div>{dateCreated ? `Created: ${dateCreated}` : null}</div>
        </div>
      </a>
    )
  }
  return <React.Fragment />
}

function CustomActivityReportTable(props) {
  const { customReportsHistory } = props
  if (customReportsHistory.length > 0) {
    return (
      <div className="activity-report-custom-table">
        {customReportsHistory.map(report => {
          return (
            <ActivityReportTableItem
              key={report.id}
              label={report.label}
              web_link={`${AIP_DJAPI_URLS.customReportWeblinkBaseUrl}${report.jwt_token}`}
              baseUrl={report.base_web_url}
              // date_range={report.date_range} ----- TODO: not yet returning from endpoint
              report_type={report.report_type_id}
              exp_date={report.expiration}
              last_requested={report.last_requested}
              date_created={report.date_created}
            />
          )
        })}
      </div>
    )
  }
  return (
    <div className="activity-report-custom-table">
      <div className="activity-report-custom-table-message">No Reports</div>
    </div>
  )
}
function ActivityReport(props) {
  const { storeId, selectedStore, DJtoken } = props
  const [pageNum, setPageNum] = useState(0)
  const [count, setCount] = useState(0)
  const [availableUsageReportsTypes, setAvailableUsageReportsTypes] = useState([])
  const [selectedUsageReportType, setSelectedUsageReportType] = useState()
  const [monthlyReports, setMonthlyReports] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [customReportStartDate, setCustomReportStartDate] = useState(new Date())
  const [customReportEndDate, setCustomReportEndDate] = useState(new Date())
  const [customGeneratedReports, setCustomGeneratedReports] = useState([])
  const [customReportName, setCustomReportName] = useState('')
  const [selectedReports, setSelectedReports] = useState([])

  useEffect(() => {
    const localStorageVal = localStorage.getItem('activityReportTypePref')
    if (localStorageVal != undefined) {
      setSelectedUsageReportType(JSON.parse(localStorageVal))
    } else {
      setSelectedUsageReportType(1)
    }
    getCustomReportTypes()
    getCustomReportHistory()
    getMonthlyActivityReports()
    setCustomReportName('')
  }, [storeId])

  const getMonthlyActivityReports = () => {
    setLoading(true)
    if (storeId > 0) {
      generateMonthlyActivityReports(DJtoken, storeId)
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res) {
            setMonthlyReports(res.reverse())
            setLoading(false)
            if (res.length === 0) {
              setError(true)
            }
          }
        })
    }
  }

  const getCustomReportTypes = () => {
    setLoading(true)
    if (storeId > 0) {
      getAvailableReportTypes(DJtoken, storeId)
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res) {
            setAvailableUsageReportsTypes(res)
            setLoading(false)
          }
        })
    }
  }

  const getCustomReportHistory = () => {
    setLoading(true)
    if (storeId > 0) {
      getCustomReportRequestHistory(DJtoken)
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res) {
            setCustomGeneratedReports(res)
            setLoading(false)
          }
        })
    }
  }

  const getGeneratedActivityReport = () => {
    setLoading(true)
    let timeAdjustedStartDate = new Date(customReportStartDate.getTime())
    let timeAdjustedEndDate = new Date(customReportEndDate.getTime())
    timeAdjustedStartDate = new Date(timeAdjustedStartDate.setUTCHours(0, 0, 0, 0))
    timeAdjustedEndDate = new Date(timeAdjustedEndDate.setUTCHours(23, 59, 59, 999))

    // checking with moment.js to be sure we have done the datetime ranges properly
    // console.log("start", moment(timeAdjustedStartDate.toISOString()).format('MMM DD, YYYY HH:mm:ss'))
    // console.log("end", moment(timeAdjustedEndDate.toISOString()).format('MMM DD, YYYY HH:mm:ss'))

    const dateRange = `${moment(customReportStartDate).format('MMM DD, YYYY')} - ${moment(
      customReportEndDate,
    ).format('MMM DD, YYYY')}`

    const labelReportName =
      customReportName.length > 0
        ? `${customReportName} - ${selectedStore.company_store_name} ${
            selectedStore.store_type == 1 ? '(New)' : '(Pre-Owned)'
          } ${dateRange}`
        : `${selectedStore.company_store_name} ${
            selectedStore.store_type == 1 ? '(New)' : '(Pre-Owned)'
          } ${dateRange}`
    generateCustomActivityReport(
      DJtoken,
      parseInt(storeId),
      selectedUsageReportType,
      timeAdjustedStartDate.toISOString(),
      timeAdjustedEndDate.toISOString(),
      labelReportName,
    )
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res && res.web_link) {
          getCustomReportHistory()
        }
      })
    setCustomReportName('')
    localStorage.setItem('activityReportTypePref', selectedUsageReportType)
  }

  function addSelectedReport(reportUrl) {
    setSelectedReports([...selectedReports, reportUrl])
  }

  function removeSelectedReport(reportUrl) {
    for (let i = 0; i < selectedReports.length; i++) {
      if (selectedReports[i] === reportUrl) {
        setSelectedReports(selectedReports.splice(i, 1))
        break
      }
    }
  }

  function ActivityReportTable() {
    let yearMappedResults = {}
    monthlyReports.map(report => {
      let year = report.month.substring(report.month.length - 4)
      if (yearMappedResults[year]) {
        yearMappedResults[year].push(report)
      } else {
        yearMappedResults[year] = [report]
      }
    })
    if (error === true) {
      return <h2 className="no-reports">There are no available Activity Reports at this time.</h2>
    }
    return (
      <div className="activity-report-tables">
        {Object.keys(yearMappedResults)
          .reverse()
          .map(year => {
            return (
              <div className="activity-report-table-container">
                <div className="activity-report-table-title">{year}</div>
                <div className="activity-report-table">
                  {yearMappedResults[year].reverse().map(report => {
                    return (
                      <ActivityReportTableItem label={report.month} web_link={report.web_link} />
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  const selectionRange = {
    startDate: customReportStartDate, // .setHours(0, 0, 0, 0)
    endDate: customReportEndDate, // .setHours(23, 59, 59, 999)
    key: 'selection',
  }
  return (
    <div className="activity-report" style={{ animation: ` 1250ms fadeIn` }}>
      {loading ? <LoadingSpinner loading /> : null}
      <Collapsible
        className="activity-report-collapsible-button-container"
        trigger={<div className="activity-report-collapsible-button">Custom Reports</div>}
        transitionTime={150}
        open={true}
      >
        <div className="activity-report-custom-container">
          <div className="activity-report-custom">
            <div className="activity-report-custom-section">
              <DateRangePicker
                ranges={[selectionRange]}
                months={1}
                direction="horizontal"
                maxDate={new Date()}
                onChange={ranges => {
                  setCustomReportStartDate(ranges.selection.startDate)
                  setCustomReportEndDate(ranges.selection.endDate)
                }}
                rangeColors={['#1cd760']}
                color="#1cd760"
              />
              <div className="custom-inputs-container">
                <input
                  placeholder="Name This Report..."
                  value={customReportName}
                  maxLength="30"
                  onChange={e => {
                    setCustomReportName(e.target.value)
                  }}
                />
                <select
                  id="custom-report-select"
                  value={selectedUsageReportType}
                  onChange={e => {
                    setSelectedUsageReportType(e.target.value)
                  }}
                >
                  {availableUsageReportsTypes.map(typesObj => (
                    <option key={typesObj.id} value={typesObj.id}>
                      {typesObj.display_name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="activity-report-custom-generate-button"
                onClick={() => {
                  getGeneratedActivityReport()
                }}
              >
                Generate Custom Report
              </button>
            </div>
            <div className="activity-report-custom-section">
              <p>Recent Reports - All Stores</p>
              <CustomActivityReportTable customReportsHistory={customGeneratedReports} />
            </div>
          </div>
        </div>
      </Collapsible>
      <Collapsible
        className="activity-report-collapsible-button-container"
        trigger={<div className="activity-report-collapsible-button">Monthly Store Reports</div>}
        transitionTime={150}
        open={true}
      >
        <ActivityReportTable />
      </Collapsible>
    </div>
  )
}

const mapStateToProps = state => ({
  storeId:
    state.tools.activeStoreId === -1
      ? state.authentication.user.user.profile.default_company_store
      : state.tools.activeStoreId,
  selectedStore: state.tools.stores.filter(function(entry) {
    return entry.company_store_id == state.tools.activeStoreId
  })[0],
})
export default connect(mapStateToProps)(ActivityReport)
