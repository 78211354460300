// general: data to be passed and pushed into an array for the header.js.
import React, { Component } from 'react'
import TopDash from '../../core/assets/images/icons/topDash-icon.js'
import TopInventory from '../../core/assets/images/icons/topInventory-icon.js'
import TopArchive from '../../core/assets/images/icons/topArchive-icon.js'
import TopCustomers from '../../core/assets/images/icons/topCustomers-icon.js'
import TopReports from '../../core/assets/images/icons/topReports-icon.js'
import TopTools from '../../core/assets/images/icons/topTools-icon.js'
import TopSticker from '../../core/assets/images/icons/topLookup-icon.js'
import ClipboardIcon from '../../core/assets/images/icons/clipboard-icon.js'

export default {
  // header options used in prod
  header_link_entries_admin: [
    {
      page_clicked: 'dashboard',
      page_url: '/dashboard',
      page_title_name: 'Dashboard',
      page_show: true,
      page_icon: <TopDash />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'packets',
      page_url: `/packets`,
      page_title_name: 'Inventory',
      page_show: true,
      page_icon: <TopInventory />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'customers',
      page_url: '/customers',
      page_title_name: 'Contacts',
      page_show: true,
      page_icon: <TopCustomers />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'tools',
      page_url: '/tools/document-upload',
      page_title_name: 'Tools',
      page_show: false,
      page_icon: <TopTools />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'reports',
      page_url: '/reports/status-report',
      page_title_name: 'Reports',
      page_show: true,
      page_icon: <TopReports />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'Sticker Pull',
      page_url: '/stickerpull',
      page_title_name: 'Sticker Pull',
      page_show: true,
      page_icon: <TopSticker />,
      page_deferToPropBool: 'showStickerPull',
    },
    {
      page_clicked: 'Archived Inventory',
      page_url: '/archived-inventory',
      page_title_name: 'Archived',
      page_show: true,
      page_icon: <TopArchive />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'Value',
      page_url: '/appraisal',
      page_title_name: 'Value',
      page_show: true,
      page_icon: <ClipboardIcon />,
      page_deferToPropBool: 'showAppraisal',
    },
  ],
  header_link_entries: [
    {
      page_clicked: 'dashboard',
      page_url: '/dashboard',
      page_title_name: 'Dashboard',
      page_show: true,
      page_icon: <TopDash />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'packets',
      page_url: `/packets`,
      page_title_name: 'Inventory',
      page_show: true,
      page_icon: <TopInventory />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'customers',
      page_url: '/customers',
      page_title_name: 'Contacts',
      page_show: true,
      page_icon: <TopCustomers />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'tools',
      page_url: '/tools/document-upload',
      page_title_name: 'Tools',
      page_show: false,
      page_icon: <TopTools />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'Sticker Pull',
      page_url: '/stickerpull',
      page_title_name: 'Sticker Pull',
      page_show: true,
      page_icon: <TopSticker />,
      page_deferToPropBool: 'showStickerPull',
    },
    {
      page_clicked: 'Archived Inventory',
      page_url: '/archived-inventory',
      page_title_name: 'Archived',
      page_show: true,
      page_icon: <TopArchive />,
      page_deferToPropBool: false,
    },
    {
      page_clicked: 'Value',
      page_url: '/appraisal',
      page_title_name: 'Value',
      page_show: true,
      page_icon: <ClipboardIcon />,
      page_deferToPropBool: 'showAppraisal',
    },
  ],

  //
  //
  //
  // hover header links used in prod
  header_link_hover_entries: [
    {
      page_clicked: 'user',
      page_url: '#account-settings',
      page_title_name: 'Account Settings',
      page_info_text: 'Change your personal information and options.',
      page_hover_on: 'user-hover',
      page_show: true,
      page_action_press: 'account-settings',
    },
    {
      page_clicked: 'user',
      page_url: '/logout',
      page_title_name: 'Logout',
      page_info_text: '',
      page_hover_on: 'user-hover',
      page_show: true,
      page_action_press: 'logout',
    },
  ],
}
