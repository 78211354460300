import React from 'react'

const PersonIcon = () => {
  return (
    <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99999 8.53334C7.26666 8.53334 6.63888 8.25482 6.11666 7.69779C5.59443 7.14075 5.33332 6.47112 5.33332 5.6889C5.33332 4.90667 5.59443 4.23704 6.11666 3.68001C6.63888 3.12297 7.26666 2.84445 7.99999 2.84445C8.73332 2.84445 9.3611 3.12297 9.88332 3.68001C10.4055 4.23704 10.6667 4.90667 10.6667 5.6889C10.6667 6.47112 10.4055 7.14075 9.88332 7.69779C9.3611 8.25482 8.73332 8.53334 7.99999 8.53334ZM2.66666 14.2222V12.2311C2.66666 11.8282 2.76388 11.4578 2.95832 11.12C3.15277 10.7822 3.4111 10.5245 3.73332 10.3467C4.42221 9.97927 5.12221 9.70371 5.83332 9.52001C6.54443 9.3363 7.26666 9.24445 7.99999 9.24445C8.73332 9.24445 9.45555 9.3363 10.1667 9.52001C10.8778 9.70371 11.5778 9.97927 12.2667 10.3467C12.5889 10.5245 12.8472 10.7822 13.0417 11.12C13.2361 11.4578 13.3333 11.8282 13.3333 12.2311V14.2222H2.66666ZM3.99999 12.8H12V12.2311C12 12.1007 11.9694 11.9822 11.9083 11.8756C11.8472 11.7689 11.7667 11.6859 11.6667 11.6267C11.0667 11.3067 10.4611 11.0667 9.84999 10.9067C9.23888 10.7467 8.62221 10.6667 7.99999 10.6667C7.37777 10.6667 6.7611 10.7467 6.14999 10.9067C5.53888 11.0667 4.93332 11.3067 4.33332 11.6267C4.23332 11.6859 4.15277 11.7689 4.09166 11.8756C4.03055 11.9822 3.99999 12.1007 3.99999 12.2311V12.8ZM7.99999 7.11112C8.36666 7.11112 8.68055 6.97186 8.94166 6.69334C9.20277 6.41482 9.33332 6.08001 9.33332 5.6889C9.33332 5.29779 9.20277 4.96297 8.94166 4.68445C8.68055 4.40593 8.36666 4.26667 7.99999 4.26667C7.63332 4.26667 7.31943 4.40593 7.05832 4.68445C6.79721 4.96297 6.66666 5.29779 6.66666 5.6889C6.66666 6.08001 6.79721 6.41482 7.05832 6.69334C7.31943 6.97186 7.63332 7.11112 7.99999 7.11112Z"
        fill="#B3B3B3"
      />
    </svg>
  )
}

export default PersonIcon
